<template xmlns:el-col="http://www.w3.org/1999/html">
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> <span class="link" @click="$router.push('/client/server/version')">服务版本列表</span> \ 版本发布
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form ref="form" :rules="rules" :model="form" label-width="120px" aria-autocomplete="none">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="服务类型" prop="serverId">
                            <span v-if="form.state===1">{{common.arrayFindReturn(servers,form.serverId)}}</span>
                            <el-select v-else v-model="form.serverId" class="form-item" placeholder="选择服务"
                                       @change="getClientServer(false)">
                                <el-option key="" label="选择服务" value="" disabled></el-option>
                                <el-option :key="item.id" :label="item.name" :value="item.id"
                                           v-for="item in servers"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="部署方式" prop="type">
                            <span v-if="form.state===1">{{form.type}}</span>
                            <el-select v-else v-model="form.type" class="form-item" placeholder="选择部署方式"
                                       @change="getClientServer(false)">
                                <el-option key="" label="选择部署方式" value="" disabled></el-option>
                                <el-option key="jar" label="jar" value="jar"></el-option>
                                <el-option key="war" label="war" value="war"></el-option>
                                <el-option key="web" label="web" value="web"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="版本号" prop="version">
                            <span v-if="form.state===1">{{form.version}}</span>
                            <el-input v-else class="form-item" v-model="form.version" placeholder="版本号,动态参数使用{version}"
                                      @change="getClientServer(false)"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="更新方式" prop="updateType">
                            <span v-if="form.state===1">{{form.updateType==='web'?'只更新代码':form.updateType==='sql'?'只执行sql':'跟随部署方式'}}</span>
                            <el-select v-else v-model="form.updateType" class="form-item" placeholder="选择更新方式">
                                <el-option key="1" label="跟随部署方式" value="normal"></el-option>
                                <el-option key="2" label="只更新代码" value="web"></el-option>
                                <el-option key="3" label="只执行sql" value="sql"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="SQL错误时继续" prop="ignoreSqlError">
                            <span v-if="form.state===1">{{form.ignoreSqlError?"SQL错误时继续":"SQL错误时中止"}}</span>
                            <el-switch v-else
                                       v-model="form.ignoreSqlError"
                                       active-color="#13ce66"
                                       inactive-color="#999"
                                       :active-value="1"
                                       :inactive-value="0">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否全量" prop="totalGas">
                            <span v-if="form.state===1">{{form.totalGas===1?'是':'否'}}</span>
                            <el-switch v-else
                                       v-model="form.totalGas"
                                       active-color="#13ce66"
                                       inactive-color="#999"
                                       :active-value="1"
                                       :inactive-value="0">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="更新内容" prop="remark">
                            <span v-if="form.state===1">{{form.remark}}</span>
                            <el-input v-else type="textarea" class="form-item full" v-model="form.remark" placeholder="更新内容,动态参数使用{remark}"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="发布日期" prop="createDate">
                            <span v-if="form.state===1">{{form.createDate.toDate("yyyy-MM-dd HH:mm:ss")}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="更新日期" prop="updateDate">
                            <span v-if="form.state===1">{{form.updateDate.toDate("yyyy-MM-dd HH:mm:ss")}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <div style="font-weight:bold;background-color: #f5f7fa;padding: 10px 0;color: #909399;font-size: 14px;width: 100%;padding-left: 10px;height: 20px;margin-top: 5px;border: 1px solid #EEE">
                        <span v-if="form.state===1">已选客户端服务</span>
                        <span v-else>可选客户端服务</span>
                        <el-button v-if="hasAuth('SaveClientServerVersion','UpdateClientServerVersion')"
                                   type="text"
                                   icon="el-icon-refresh"
                                   class="refresh-btn"
                                   @click="()=>{form.state===1?getClientServer(true,{ids:form.clientServerIds}):getClientServer(true)}"
                        >刷新
                        </el-button>
                    </div>
                </el-row>
                <el-row>
                    <el-table
                            ref="table"
                            :data="tableData"
                            border
                            class="table"
                            header-cell-class-name="table-header"
                            @select="selection"
                    >
                        <el-table-column type="selection" width="55" v-if="form.state!==1"></el-table-column>
                        <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                        <el-table-column prop="clientId" label="客户端" align="center" width="150">
                            <template #default="scope">
                                <span>{{common.arrayFindReturn2(clients,scope.row.clientId,"id","name,ip")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="名称" align="center"></el-table-column>
                        <el-table-column prop="serverId" label="服务" align="center">
                            <template #default="scope">
                                <span>{{common.arrayFindReturn(servers,scope.row.serverId)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="version" label="当前版本" align="center">
                            <template #default="scope">
                                {{scope.row.version||'无版本'}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="ports" label="服务端口号" align="center" v-if="form.type!=='web'">
                            <template #default="scope">
                                    <span v-for="(item,index) in scope.row.ports" :key="index"><span
                                            v-if="index>0">,</span>
                                     <span :class="{success:scope.row.runPorts&&scope.row.runPorts.indexOf(item)>=0}">{{item}}</span>
                                    </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="closePorts" label="关闭端口号" align="center" v-if="form.type==='war'">
                            <template #default="scope">
                                    <span v-for="(item,index) in scope.row.closePorts" :key="index"><span
                                            v-if="index>0">,</span>
                                    <span :class="{success:scope.row.runClosePorts&&scope.row.runClosePorts.indexOf(item)>=0}">{{item}}</span>
                                    </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="path" label="路径" align="center"></el-table-column>
                        <el-table-column prop="process" label="更新结果" align="center" v-if="form.state===1">
                            <template #default="scope">
                                <div v-if="scope.row.process">
                                    <span v-if="scope.row.process.state===0">未开始</span>
                                    <span v-if="scope.row.process.state===1" class="success">更新完成</span>
                                    <span v-else-if="scope.row.process.state===-1" class="error">更新失败</span>
                                    <span v-else-if="scope.row.process.state===2">正在更新</span> ({{scope.row.process.process||0}}%)
                                    <div>{{scope.row.process.remark}}</div>
                                    <el-button v-if="scope.row.process.state===-1" type="text" @click="isBack?back(scope.row.id):audit(scope.row.id)">重试</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <br/>
                <el-row v-if="key!=null && form.state===1">
                    <el-col :span="24">
                        <div style="text-align:left;background-color: #f5f7fa;padding: 14px;color: #909399;font-size: 14px;font-weight: bold;border: 1px solid #EEE">
                            更新日志
                        </div>
                        <ul ref="logs" class="scan-log" :style="{height:common.calcTableHeight(590)+'px'}">
                            <li v-for="(log,index) in logs" class="infinite-list-item" :key="index" :class="{error:log.level==='error'}">{{log.msg}}</li>
                        </ul>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-upload
                                class="upload-item"
                                drag
                                :disabled="form.state===1"
                                show-file-list
                                :file-list="form.versionPackage"
                                :limit="1"
                                :action="$store.state.serviceUrl+'/file/pre/upload'"
                                :headers="getUploadHeader"
                                :on-success="(res,file)=>{handleAvatarSuccess(res,file,'versionPackageId')}"
                                accept=".zip">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" v-if="form.state===1">更新包</div>
                            <div class="el-upload__text" v-else>将代码更新包拖到此处，或<em>点击上传</em></div>
                            <template #tip>
                                <div class="el-upload__tip">更新包:只能上传 zip 压缩文件，且不超过 500MB</div>
                            </template>
                        </el-upload>
                        <el-upload v-if="form.type!=='web'"
                                class="upload-item"
                                drag
                                :disabled="form.state===1"
                                show-file-list
                                :file-list="form.versionSql"
                                :limit="1"
                                :action="$store.state.serviceUrl+'/file/pre/upload'"
                                :headers="getUploadHeader"
                                :on-success="(res,file)=>{handleAvatarSuccess(res,file,'versionSqlId')}"
                                accept=".sql">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" v-if="form.state===1">sql包</div>
                            <div class="el-upload__text" v-else>将sql包拖到此处(可选)，或<em>点击上传</em></div>
                            <template #tip>
                                <div class="el-upload__tip">sql包:只能上传 sql 文件，且不超过 100MB</div>
                            </template>
                        </el-upload>
                        <div class="sql-item-div" v-if="form.type!=='web'">
                            <ul class="sql-item" v-if="form.state===1">
                                <li v-for="(item,index) in form.sql" :key="index" :title="item.sql" class="multi-line">{{index+1}}、{{item.sql}}</li>
                            </ul>
                            <el-checkbox-group v-model="form.sqlIds" class="sql-item" v-else>
                                <el-checkbox :label="item.id"  v-for="(item,index) in bases.updateSql" :key="index" :title="item.other" :disabled="form.state===1">{{item.name}} {{item.other}}</el-checkbox>
                            </el-checkbox-group>
                            <span class="sql-item-span">内置sql:动态参数使用方式为{参数名}</span>
                        </div>

                    </el-col>
                </el-row>
                <br/>
                <el-row>
                    <el-col :span="24">
                        <div style="text-align: right">
                            <el-button v-if="hasAuth('SaveClientServerVersion','UpdateClientServerVersion') && form.state===0"
                                       type="primary"
                                       icon="el-icon-upload"
                                       @click="saveEdit()"
                            >保存
                            </el-button>
                            <el-button  v-if="hasAuth('AuditClientServerVersion') && form.id && form.state===0"
                                       type="success"
                                       icon="el-icon-success"
                                       @click="audit()"
                            >确认并更新
                            </el-button>
                            <el-button  v-if="hasAuth('AuditClientServerVersion') && form.state===1"
                                        type="warning"
                                        icon="el-icon-refresh-left"
                                        @click="back()"
                            >回退到此版本
                            </el-button>
                        </div>
                    </el-col>
                </el-row>

            </el-form>
        </div>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import store from "../../store";
    import api from "../../utils/api";

    export default {
        name: "ClientServerUpdate",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                pageTotal: 0,
                form: {sqlIds:[],clientServerIds:[],state:0},
                loading: false,
                servers: [],
                clients: [],
                clientServer: {id: null},
                logs: [],
                bases:{updateSql:[]},
                rules: {
                    version: [{required: true, message: "请填写版本号", trigger: "blur"}],
                    serverId: [{required: true, message: "请选择服务类型", trigger: "blur"}],
                    type: [{required: true, message: "请选择部署方式", trigger: "blur"}],
                    versionPackage: [{required: true, message: "还未上传更新包", trigger: "blur"}],
                },
                key:null,
                //版本回退
                isBack:0,
                updateDone:[false],
                //询问消息显示时间,需要割开时间显示
                askTime:0,
            };
        },
        created() {
            this.init();
            this.getServer();
            api.getBase("update_sql",(data)=>{
                this.bases.updateSql = data;
            })
        },
        methods: {
            init(){
                this.clientServer.id = this.$route.query.clientServerId;
                this.isBack = this.$route.query.back;
                this.key = this.$route.query.key;
                if (this.clientServer.id) {
                    this.clientServer.id = parseInt(this.clientServer.id);
                }
                this.form.serverId = this.$route.query.serverId;
                this.form.id = this.$route.query.id;
                if (this.form.serverId) {
                    this.form.serverId = parseInt(this.form.serverId);
                }
                this.form.type = this.$route.query.type;
                if(this.form.id){
                    //id不为空时
                    this.getData(this.form.id);
                }else if(this.form.type&&this.form.serverId&&this.clientServer.id){
                    //类型和服务不为空时
                    this.form.clientServerIds.push(this.clientServer.id);
                    this.getClientServer();
                    this.form.state=0;
                }
            },
            //获取数据
            getData(id){
                http.post("/client/server/version/get", {id: id}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        this.form = data.data;
                        if(!this.form){
                            return;
                        }
                        this.form.clientServerIds = [];
                        this.form.sqlIds = [];
                        for (let i = 0; i < this.form.clientServer.length; i++) {
                            this.form.clientServerIds.push(this.form.clientServer[i].clientServerId);
                        }
                        if(this.form.versionPackage&&this.form.versionPackage.length>0){
                            let files = [];
                            for (let i = 0; i < this.form.versionPackage.length; i++) {
                                files.push(this.form.versionPackage[i].fid);
                            }
                            this.form.versionPackageId = files.join(",");
                        }
                        if(this.form.versionSql&&this.form.versionSql.length>0){
                            let files = [];
                            for (let i = 0; i < this.form.versionSql.length; i++) {
                                files.push(this.form.versionSql[i].fid);
                            }
                            this.form.versionSqlId = files.join(",");
                        }
                        if(this.form.sql&&this.form.sql.length>0){
                            for (let i = 0; i < this.form.sql.length; i++) {
                                this.form.sqlIds.push(this.form.sql[i].sqlId);
                            }
                        }
                        if(this.form.state===1){
                            this.getClientServer(null,{ids:this.form.clientServerIds});
                            if(this.key){
                                this.updateDone[0] = false;
                                this.$nextTick(function () {
                                    this.logProcess();
                                });
                            }
                        }else{
                            this.getClientServer()
                        }
                        return;
                    }
                    this.$message.error(data.msg);
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取客户端服务列表
            getClientServer(force,param,refresh) {
                if(!param){
                    param = {
                        serverId: this.form.serverId,
                        type: this.form.type,
                        state: 1,
                        ltVersion: this.form.version,
                    };
                }
                param['page'] = 1;
                param['rows'] = 100;
                if (!this.form.serverId) {
                    if (force) {
                        this.$message.error("请选择客户端服务");
                    }
                    return;
                }
                if (!this.form.type) {
                    if (force) {
                        this.$message.error("请选择部署方式");
                    }
                    return;
                }
                if (!this.form.version&&force) {
                    this.$message.error("请填写版本号");
                }
                http.post("/client/server/list",param ,{animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        if(refresh){
                            for (let i = 0; i < data.rows.length; i++) {
                                let row = data.rows[i];
                                let obj = common.arrayFindReturnObj(this.tableData,row.id);
                                obj.version = row.version;
                                obj.runCount = row.runCount;
                                obj.runClosePorts = row.runClosePorts;
                                obj.runPorts = row.runPorts;
                            }
                        }else{
                            this.tableData = data.rows;
                            this.pageTotal = data.total;
                            this.getClientByIds(common.getIds(this.tableData, "clientId"));
                        }
                        if (this.tableData.length>0) {
                            this.getServerVersionChild();
                        }
                        this.$nextTick(function () {
                            for (let i = 0; i < this.tableData.length; i++) {
                                if(this.form.clientServerIds.indexOf(this.tableData[i].id)>=0){
                                    this.$refs.table.toggleRowSelection(this.tableData[i])
                                }
                            }
                        });
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取版本结果
            getServerVersionChild(){
                http.post("/client/server/version/get/child", {id: this.form.id},{animation: store.state.Animation.NONE}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        data = data.data;
                        if(data){
                            let done = true;
                            for (let i = 0; i < data.length; i++) {
                                if(data[i].state!==2){
                                    let obj =common.arrayFindReturnObj(this.tableData,data[i].clientServerId);
                                    if(obj){
                                        obj.process = data[i];
                                    }
                                }else{
                                    done = false;
                                }
                            }
                            this.updateDone[0] = done;
                        }
                    }
                });
            },
            //获取服务
            getServer() {
                http.post("/server/list").then(data => {
                    data = data.data;
                    if (data) {
                        this.servers = data;
                    }
                })
            },
            //获取客户端
            getClientByIds(ids) {
                http.post("/client/get/ids", ids, {
                    headers: {"Content-Type": 'application/json;charset=utf-8'},
                    animation: store.state.Animation.NONE
                }).then(data => {
                    data = data.data;
                    if (data) {
                        this.clients = data;
                    }
                })
            },
            //选择
            selection(rows){
                this.form.clientServerIds = [];
                if(rows&&rows.length>0){
                    for (let i = 0; i < rows.length; i++) {
                        this.form.clientServerIds.push(rows[i].id);
                    }
                }
            },
            // 保存编辑
            saveEdit() {
                //获取选中客户端服务
                this.$refs.form.validate(valid => {
                    if (valid) {
                        http.post(this.form.id ? '/client/server/version/update' : '/client/server/version/save', this.form, {alertError: true, headers: {"Content-Type": 'application/json;charset=utf-8'},}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success(`操作成功`);
                                if(!this.form.id){
                                    this.$router.push({path:'/client/server/version/edit',query:{id:data.data.id}})
                                    return;
                                }
                                this.getData(data.data.id);
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    }
                });

            },
            // 确认
            audit(clientServerId) {
                this.$confirm(clientServerId!=null?"确定要重新尝试更新操作吗":"确定后开始更新不能再变更,确认要继续吗？", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        http.post('/client/server/version/audit', {id:this.form.id,clientServerId:clientServerId,key:this.key}, {alertError: true}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success(`操作成功`);
                                if(!this.key||this.key!==data.data){
                                    this.$router.push({path:'/client/server/version/edit',query:{id:this.form.id,key:data.data}})
                                }else{
                                    this.init();
                                }
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            // 确认
            back(clientServerId) {
                this.$confirm("确定要将已成功更新的服务还原至这个版本吗", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        http.post('/client/server/version/back', {id:this.form.id,clientServerId:clientServerId,key:this.key}, {alertError: true}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success(`操作成功`);
                                if(!this.key||this.key!==data.data){
                                    this.$router.push({path:'/client/server/version/edit',query:{id:this.form.id,back:1,key:data.data}})
                                }else{
                                    this.init();
                                }
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            //日志进度
            logProcess(){
                api.getTaskLog(this.key,this.logs,this.$refs.logs,(data)=>{
                    if(data.state===3){
                        let time = +new Date();
                        if(this.askTime+500>time){
                            this.askTime += 500;
                        }else{
                            this.askTime = time;
                        }
                        setTimeout(()=>{
                            let close = false;
                            let notify = this.$notify({
                                duration: 10000,
                                title: '来自客户端的询问(10秒后自动取消)',
                                dangerouslyUseHTMLString: true,
                                message: data.msg + '<br/><b>单击确认</b>',
                                onClose: () => {
                                    if(!close){
                                        this.$message.success(`已取消`);
                                        http.post('/client/task/wait/ask/update',
                                            {id: data.key,state: 0},
                                            {animation: store.state.Animation.NONE});
                                    }
                                },
                                onClick: () => {
                                    this.$message.success(`已确认`);
                                    http.post('/client/task/wait/ask/update',
                                        {id: data.key,state: 1},
                                        {animation: store.state.Animation.NONE});
                                    close = true;
                                    notify.close();
                                }
                            });
                        },this.askTime-time);
                    }
                    if(data.clientServerId){
                        let row = common.arrayFindReturnObj(this.tableData,data.clientServerId);
                        if(row){
                            row.process = data;
                        }
                    }
                },()=>{
                    let interval = setInterval(()=>{
                        this.getClientServer(null,{ids:this.form.clientServerIds},true);
                        if(this.updateDone[0]){
                            setTimeout(()=>{
                                this.getClientServer(null,{ids:this.form.clientServerIds},true);
                            },2000);
                            clearInterval(interval);
                        }
                    },5000);

                },this.updateDone);
            },
            //上传完成
            handleAvatarSuccess(res, file, type) {
                if (res.code === this.$store.state.ResultCode.OK) {
                    this.form[type] = res.data[0];
                } else {
                    file.status = 'error';
                    this.$message.error(res.msg);
                }
            },
        },
        computed: {
            //获取上传请求头
            getUploadHeader() {
                return {tk: localStorage.getItem('tk')}
            }
        },
        watch: {
            $route() {
                if (this.$route.name === "EditClientServerVersion") {
                    //初始化
                      this.init();
                }
            }
        },
    };
</script>

<style scoped>
    .container {
        padding: 25px;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .scan-log {
        width: 100%;
        border: 1px solid #EEE;
        overflow: auto;
    }

    .refresh-btn {
        margin: 0 10px;
        min-height: auto;
        height: 20px;
        padding: 0;
    }

    .upload-item {
        width: 360px;
        display: inline-block;
        margin-left: 10px;
        float: left;
    }
    .sql-item{
        overflow: auto;
        height: 170px;
        width: calc(100% - 770px);
        display: inline-block;
        margin-right: 10px;
        list-style: none;
        border: 1px solid #EEE;
        float: right;
        padding: 5px;
    }
    .sql-item-div{
        height: 200px;
    }
    .sql-item-span{
        font-size: 12px;
        color: #606266;
        margin-top: 10px;
        padding: 10px;
    }
</style>
